import React, { useState, useRef, useEffect,useContext,createContext} from "react";
import styled from "styled-components";
import {EditContext} from "../form/Edit";
import {BaseFormField,Button,FormSubmit,FormCancel} from "../../styles/Global";
import axios from "axios";
import mainjson from '../../config/main.js';

const Container = styled.div`
display:inline-block;

`
const Background = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.5);
    z-index:4000;
`

const InnerContent = styled.div`
   padding:20px;
`

const CenterBlock = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    width:400px;
    height:200px;
    background-color:white;
    z-index:4001;
    border-radius:5px;
`

const ListTitle = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: 600;
  display:block;
`;

const Buttons = styled.div`
  position:absolute;
  bottom:20px;
`;

export default function ChooseOverlay(props) {
    const [filterList, setFilterList] = useState([]);
    const {showExtraComponent,setShowExtraComponent,refreshList,setRefreshList} = useContext(EditContext);
    const dropdown = useRef();
    const [value, setValue] = useState("1000");
    const [generating, setGenerating] = useState(false);

    function close(){
        console.log("close overlay")
        setShowExtraComponent(false);
    }
    
    function donotclose(e){
        e.stopPropagation();
    }

    function changed(e){   
        setValue(e.target.value);
    }
    
    async function submit(){
        let nr = parseInt(value);
        let resultdata = {};
        if (!isNaN(nr) && props.id){
            setGenerating(true);
            let body = {nr:nr,campaign:props.id};
            await axios({
                method: "post",
                url: mainjson().generate_codes_path,
                data: body,
            })
            .then((response) => {
            if (response.data) {
                resultdata = response.data;
            }
            })
            .catch((error) => {
                console.log("error "+error);
            });
        }
        else {
            alert("Geen nummer ingevuld");
        }

        if (resultdata && resultdata.data.codes) {
            alert(resultdata.data.codes+" codes gegenereerd");
        }
        setRefreshList(true);

        close();

    }
     

    return (
       
       <Background onMouseDown={close}>
            <CenterBlock onMouseDown={donotclose}>
            <InnerContent>
                <ListTitle>Number of codes to generate:</ListTitle>
                <BaseFormField type='text' onChange={changed} value={value||""}/>
                <Buttons>
                {!generating && <FormSubmit onClick={submit}>Generate</FormSubmit> } <FormCancel onClick={close}>Cancel</FormCancel>
                </Buttons>
            </InnerContent>
            </CenterBlock>

       </Background>

    );
}